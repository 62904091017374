// !client-only

export function openLiveChat(): void {
  const delay = 500;
  setTimeout(() => {
    if (window.LC_API) {
      window.LC_API.open_chat_window();
    } else {
      console.warn('LiveChat has not been initialized yet.');
    }
  }, delay);
}
