'use client';

import { useState, Fragment } from 'react';
import { uniqueId } from 'lodash';
import { Icon } from '@/components/atoms/Icon';

import styles from './index.module.scss';

interface ContentProps {
  subtitle: string;
  uri: string;
}

interface ItemProps {
  title: string;
  content: ContentProps[];
}

interface Props {
  items: ItemProps[];
  activeUri?: string;
}

export default function FaqAccordionItems({ items, activeUri }: Props): JSX.Element {
  const [activeIndex, setActiveIndex] = useState(
    items.findIndex((item) => item.content.some((content) => content.uri === activeUri))
  );

  const handleClick = (index: number) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  return (
    <div className={styles.accordion}>
      {items.map((item, index) => (
        <Fragment key={uniqueId(item.title)}>
          <button
            className={`${styles.accordionButton} ${activeIndex === index ? styles.isOpen : ''}`}
            onClick={() => handleClick(index)}
            type="button"
          >
            <Icon
              id="arrows/chevron-down"
              legacy={false}
              width={20}
              height={20}
              className={`${styles.accordionIcon} ${activeIndex === index ? styles.rotate : ''}`}
            />
            {item.title}
          </button>
          {activeIndex === index && (
            <div className={`${styles.accordionContent} ${activeIndex === index ? styles.isOpen : ''}`}>
              <ul>
                {item.content.map((line) => (
                  <li key={uniqueId(line.uri)} className={line.uri === activeUri ? styles.active : ''}>
                    <a href={`${line.uri}`}>{line.subtitle}</a>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
}

FaqAccordionItems.displayName = 'FaqAccordionItems';
