'use client';

import { openLiveChat } from '@/components/templates/ThirdPartyScripts/LiveChat/util';
import { Button, DesignType } from '@/components/atoms/Button';

export function CallUsButton({
  text,
  number,
  className,
}: {
  text: string;
  number: string;
  className?: string;
}): JSX.Element {
  return (
    <Button
      className={className || ''}
      designType={DesignType.PRIMARY}
      href={`tel:${number}`}
      iconId="communication/phone"
    >
      {text}
    </Button>
  );
}

export function LiveChatButton({ text, className }: { text: string; className?: string }): JSX.Element {
  return (
    <Button
      className={className || ''}
      designType={DesignType.SECONDARY_LIGHT}
      iconId="communication/message-chat-circle"
      onClick={() => {
        openLiveChat();
      }}
    >
      {text}
    </Button>
  );
}

export function EmailUsButton({
  text,
  email,
  className,
}: {
  text: string;
  email: string;
  className?: string;
}): JSX.Element {
  return (
    <Button
      className={className || ''}
      designType={DesignType.SECONDARY_LIGHT}
      href={`mailto:${email}`}
      iconId="communication/mail-01"
    >
      {text}
    </Button>
  );
}
